import React from 'react'
import Figure from './Figure'
import tableStyles from './table.module.css'

const serializers = {
  types: {
    table: ({node})=> (node.rows.length > 0 && <table className={tableStyles.table}>
      {console.log(node)}
      <thead>
        <tr>{node.rows[0].cells.map(cell=><th key={cell._key}>{cell.value}</th>)}</tr>
        </thead>
        <tbody>
          {node.rows.slice(1,node.rows.length).map(row=><tr key={row._key}>{row.cells.map(cell=><td key={cell._key}>{cell.value == "<check>" ? <span className={tableStyles.checkmark}></span>:cell.value=="<x>"?<span className={tableStyles.x}></span>:cell.value}</td>)}</tr>)}
        </tbody>
    </table>) ||<></>,
    authorReference: ({node}) => <span>{node.author.name}</span>,
    sourceReference: ({node}) => <a style={{"textDecoration":"none"}}href={"#"+encodeURI(node.source.title)}><sup>{node.source.index}</sup></a>,
    mainImage: Figure
  }
}

export default serializers
